.App {
  text-align: center;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #75001f;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* CUSTOM CSS */
.iframe {
  width: 100%;
}

.info {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
}

.infocontainer {
  margin-top: 10rem;
}

.iframe {
  height: 80vh !important;
  width: 100%;
  border: none;
  /* style="height:500px;width:99%;border:none;" */
}

.pageFotDef,
.alignNext,
.saveBtnCont,
.saveBtn,
.nextAlign,
.prevAlign,
.next_previous,
.alignSubmit,
.submitBtnCont,
.submitColor,
.reviewBtnCont,
.reviewBtn {
  width: 100%;
}

.calendarCont {
  width: 100% !important;
}

input#Date-date {
  width: 100% !important;
}